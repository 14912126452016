import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import {
  ContactForm,
  PostHero,
  PostList,
  HeroWrapper,
  DetailedFooter,
  SliderHero,
  Layout
} from '../components';
import { ExpandMore } from '@material-ui/icons';
import config from '../../config';

export const query = graphql`
  query IndexQuery {
    postHeroQuery: markdownRemark(
      frontmatter: { postHero: { eq: true }, active: { eq: true } }
    ) {
      excerpt(pruneLength: 230)
      fields {
        slug
      }
      frontmatter {
        title
        type
        service
        category
        cover {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    postListHtml: markdownRemark(fileAbsolutePath: { regex: "*/post-list/" }) {
      id
      html
    }
    postList: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "//posts/" }
        frontmatter: { featuredOrder: { gt: 0 }, active: { eq: true } }
      }
      sort: { fields: [frontmatter___featuredOrder], order: ASC }
      limit: 3
    ) {
      edges {
        node {
          excerpt(pruneLength: 90)
          id
          fields {
            slug
          }
          frontmatter {
            title
            service
            category
            cover {
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    bgDesktopHeader: imageSharp(
      fluid: { originalName: { regex: "/hero-background-header/" } }
    ) {
      resize(width: 1400, cropFocus: CENTER) {
        src
      }
    }
    bgTabletHeader: imageSharp(
      fluid: { originalName: { regex: "/hero-background-header/" } }
    ) {
      resize(width: 700, cropFocus: CENTER) {
        src
      }
    }
    bgMobileHeader: imageSharp(
      fluid: { originalName: { regex: "/hero-background-header/" } }
    ) {
      resize(width: 400, cropFocus: CENTER) {
        src
      }
    }

    bgDesktopFooter: imageSharp(
      fluid: { originalName: { regex: "/hero-background-footer_optimized/" } }
    ) {
      resize(width: 1400, cropFocus: CENTER) {
        src
      }
    }
    bgTabletFooter: imageSharp(
      fluid: { originalName: { regex: "/hero-background-footer_optimized/" } }
    ) {
      resize(width: 700, cropFocus: CENTER) {
        src
      }
    }
    bgMobileFooter: imageSharp(
      fluid: { originalName: { regex: "/hero-background-footer_optimized/" } }
    ) {
      resize(width: 400, cropFocus: CENTER) {
        src
      }
    }
  }
`;

const HrSeparator = styled.hr`
  margin: 0;
  border: 0;
  height: 0;
  position: relative;
  top: -${props => props.theme.menu.height.fixed};
`;

const ScrollButton = styled.button`
  background: transparent;
  border: 0;
  cursor: pointer;
  position: absolute;
  bottom: 1vh;
  right: 4vw;

  &:focus {
    outline-style: none;
  }

  svg {
    font-size: ${props => props.theme.font.size.large};
    position: relative;
    animation-duration: ${props => props.theme.animation.duration.long};
    animation-name: buttonIconMove;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(0.39, 0.58, 0.57, 1);
    color: ${props => props.theme.color.lightest};
  }

  @keyframes buttonIconMove {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-12px);
    }
    100% {
      transform: translateY(0);
    }
  }

  ${props => props.theme.media.tablet`
    svg {
      font-size: ${props => props.theme.font.size.xxLarge};
    }
  `};
`;

export default class Index extends React.Component {
  separator = React.createRef();

  scrollToContent = e => {
    this.separator.current.scrollIntoView({
      block: 'start',
      behavior: 'smooth'
    });
  };

  render() {
    const {
      data: {
        postHeroQuery: postHero,
        postListHtml: { html: postListHtml },
        postList: { edges: list },
        bgDesktopHeader: {
          resize: { src: desktopHeader }
        },
        bgTabletHeader: {
          resize: { src: tabletHeader }
        },
        bgMobileHeader: {
          resize: { src: mobileHeader }
        },
        bgDesktopFooter: {
          resize: { src: desktopFooter }
        },
        bgTabletFooter: {
          resize: { src: tabletFooter }
        },
        bgMobileFooter: {
          resize: { src: mobileFooter }
        }
      }
    } = this.props;

    const scale = true;

    return (
      <Layout>
        <HeroWrapper
          backgroundMobile={mobileHeader}
          backgroundTablet={tabletHeader}
          backgroundDesktop={desktopHeader}
          scale={scale ? 1 : 0}
        >
          {() => (
            <React.Fragment>
              <SliderHero
                autoplay={config.slider.autoplay}
                slides={config.slider.slides}
              />
              <ScrollButton onClick={this.scrollToContent} aria-label="scroll">
                <ExpandMore />
              </ScrollButton>
            </React.Fragment>
          )}
        </HeroWrapper>
        <HrSeparator ref={this.separator} />
        <PostHero post={postHero} />
        <PostList
          buttonText="Ver más cursos y talleres"
          list={list}
          htmlHeader={postListHtml}
        />
        <ContactForm
          mailto={config.contactInfo.mailto}
          phoneNumberUrl={config.contactInfo.phoneNumberUrl}
          phoneNumberText={config.contactInfo.phoneNumberText}
        />
        <HeroWrapper
          backgroundMobile={mobileFooter}
          backgroundTablet={tabletFooter}
          backgroundDesktop={desktopFooter}
        >
          {() => <DetailedFooter />}
        </HeroWrapper>
      </Layout>
    );
  }
}
